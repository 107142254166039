.card-counter {
  box-shadow: 2px 2px 10px #dadada;
  margin: 5px;
  padding: 20px 10px;
  background-color: #fff;
  height: 100px;
  border-radius: 5px;
  transition: 0.3s linear all;
}

.card-counter:hover {
  box-shadow: 4px 4px 20px #dadada;
  transition: 0.3s linear all;
}

.card-counter.primary {
  background-color: #007bff;
  color: #fff;
}

.card-counter.danger {
  background-color: #ef5350;
  color: #fff;
}

.card-counter.success {
  background-color: #66bb6a;
  color: #fff;
}

.card-counter.info {
  background-color: #26c6da;
  color: #fff;
}

.card-counter i {
  font-size: 5em;
  opacity: 0.2;
}

.card-counter .count-numbers {
  position: absolute;
  right: 35px;
  top: 20px;
  font-size: 32px;
  display: block;
}

.card-counter .count-name {
  position: absolute;
  right: 35px;
  top: 65px;
  font-style: italic;
  text-transform: capitalize;
  opacity: 0.5;
  display: block;
  font-size: 18px;
}

.btnprimary {
  background-color: #c82590;
  border-color: #c82590;
  color: #fff;
}

.btnsecondary {
  background-color: #f79461;
  border-color: #f79461;
  color: #fff;
}

.btnwhite {
  background-color: #fff;
  border-color: #fff;
  color: #000;
}

/*New Style*/
.top-list {
  display: flex;
  list-style: none;
  flex-wrap: nowrap;
  justify-content: center;
}

.list-item {
  /* // border-right: solid 2px #d4d4d4; */
  background-color: #d4d4d4;
  padding: 10px;
  border-right: 0.3px solid #f4f7fa;
  color: #fffcf4;
  font-size: medium;
}

.list-item.active,
.list-item:hover {
  background-color: #f46a29;
  transition: ease-in 0.5s;
}

.list-item.pactive {
  background-color: purple;
  transition: ease-in 0.5s;
}

/* .pannel {
  background-color: #fff;
  width: 80%;
  margin: 30px auto;

  padding: 30px 30px;
  border-radius: 6px;
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
} */

.head {
  font-family: Mitr, sans-serif;
  color: #f46a29;
}

.btn-secondary {
  color: #fff;
  background-color: #f46a29;
  border-color: #f46a29;
}

#personal-details {
  border-radius: 4px, 0 0 4px;
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.label {
  padding: 10px;
  background-color: #f46a29;
  color: #fff;
  font-size: 12px;
  border-radius: 4px;
  cursor: pointer;
}


/* Screen loader */


.loader {
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 6px solid #1d1922;
  border-bottom: 3px solid #7b0e0e;
  border-left: 2px solid #360f67;
  border-right: 4px solid #33323b;
  width: 80px;
  height: 80px;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
  background: inherit;
  display: block;
  text-align: center;
  padding: 4px 2px;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.loader-wrapper{
  height: 100vh;
  background: hsla(150, 25%, 98%, 0.5);
  position: absolute;
  width: 100vw;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  
}


.p {
  animation-duration: 3s;
  animation-name: slidein;
}

@keyframes slidein {
  from {
    margin-left: 100%;
    width: 300%;
  }

  75% {
    font-size: 300%;
    margin-left: 25%;
    width: 150%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}
